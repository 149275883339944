import { green, indigo, lightGreen, teal } from "@mui/material/colors";
import grey from "@mui/material/colors/grey";
import { createTheme } from "@mui/material/styles";

export const getTheme = () =>
  createTheme({
    typography: {
      fontFamily: [
        "Noto Sans KR",
        "-apple-system",
        "BlinkMacSystemFont",
        "sans-serif",
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(","),

      allVariants: {
        color: grey[800],
      },
    },
    components: {
      MuiDivider: {
        styleOverrides: {
          root: {
            backgroundColor: green[100],
          },
        },
      },
    },
    palette: {
      mode: "light",
      primary: {
        main: teal[900],
      },
      secondary: grey,
      background: {
        default: grey[100],
        paper: "#fff",
      },
    },

    breakpoints: {
      values: {
        xs: 0,
        sm: 640,
        md: 845,
        lg: 1120,
        xl: 1280,
      },
    },
  });
