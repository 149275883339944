"use client";

import { useState } from "react";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { ThemeProvider as TmProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { useServerInsertedHTML } from "next/navigation";

import { getTheme } from "./theme";

export const ThemeProvider = (props: any) => {
  const { options = { key: "mui" }, children } = props;

  const [{ cache, flush }] = useState(() => {
    const cache = createCache(options);
    cache.compat = true;
    const prevInsert = cache.insert;
    let inserted: string[] = [];
    cache.insert = (...args) => {
      const serialized = args[1];
      if (cache.inserted[serialized.name] === undefined) {
        inserted.push(serialized.name);
      }
      return prevInsert(...args);
    };
    const flush = () => {
      const prevInserted = inserted;
      inserted = [];
      return prevInserted;
    };
    return { cache, flush };
  });

  useServerInsertedHTML(() => {
    const names = flush();
    if (names.length === 0) {
      return null;
    }
    let styles = "";
    for (const name of names) {
      styles += cache.inserted[name];
    }
    return (
      <style
        key={cache.key}
        data-emotion={`${cache.key} ${names.join(" ")}`}
        dangerouslySetInnerHTML={{
          __html: options.prepend ? `@layer emotion {${styles}}` : styles,
        }}
      />
    );
  });

  const theme = getTheme();

  return (
    <>
      {/* <DarkModeContext.Provider value={value}> */}
      <CacheProvider value={cache}>
        <TmProvider theme={theme}>
          <CssBaseline />
          {children}
        </TmProvider>
      </CacheProvider>
      {/* </DarkModeContext.Provider> */}
    </>
  );
};

// export const useDarkMode = () => {
//   const context = useContext(DarkModeContext);
//   if (context === undefined) {
//     throw new Error("useDarkMode must be used within a DarkModeProvider");
//   }
//   return context;
// };
